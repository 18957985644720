export function isMobile() {
    const userAgent = navigator.userAgent;
    const uaMatch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  
    const hasTouchScreen = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
    const isPortrait = window.innerHeight > window.innerWidth;
    const smallScreen = window.screen.width < 640;
  
    const isMobileDevice = uaMatch || (hasTouchScreen && (isPortrait || smallScreen))
    return isMobileDevice;
  }